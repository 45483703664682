<template>
  <div class="resolve">
    <Breadcrumb
      class="breadcrumb"
      :customBreadcrumbList="[{ name: 'Browse', text: 'Browse' }]"
    >
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ breadcrumbTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="
            question_detail &&
              question_detail.chapter_title &&
              question_detail.chapter_title !== ''
          "
        >
          {{ question_detail.chapter_title }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="question_detail.chapter_order">
          {{ `# ${question_detail.chapter_order}` }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <template v-if="skill == 1">
      <ReadingM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        isBrowse="true"
      ></ReadingM>
      <Reading
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        isBrowse="true"
      ></Reading>
    </template>

    <template v-if="skill == 2">
      <ListeningM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        isBrowse="true"
      ></ListeningM>
      <Listening
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        isBrowse="true"
      ></Listening>
    </template>
    <template v-if="skill == 3">
      <SpeakingM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
        isBrowse="true"
      ></SpeakingM>
      <Speaking
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
        isBrowse="true"
      ></Speaking>
    </template>
    <template v-if="skill == 4">
      <WritingM
        v-if="isPhone"
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
        isBrowse="true"
      ></WritingM>
      <Writing
        v-else
        :question_detail="question_detail"
        :question_tags="question_tags"
        :title="title"
        :lang="lang.value"
        :examInfo="exam_info"
        isBrowse="true"
      ></Writing>
    </template>
    <Lock
      ref="lock"
      :userExamId="user_exam_id"
      :examInfo="exam_info"
      :pointPackages="pointPackages"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Reading from "./Reading";
import Listening from "./Listening";
import Speaking from "./Speaking";
import Writing from "./Writing";
import ReadingM from "./mobile/Reading";
import ListeningM from "./mobile/Listening";
import SpeakingM from "./mobile/Speaking";
import WritingM from "./mobile/Writing";
import TOEFL from "@/apis/toefl";
import Lock from "@/views/toefl/transcript/components/Lock";

export default {
  metaInfo() {
    return {
      title: "Browse - " + this.title + " | TestAdmit"
    };
  },

  components: {
    Breadcrumb,
    Reading,
    Listening,
    Speaking,
    Writing,
    ReadingM,
    ListeningM,
    SpeakingM,
    WritingM,
    Lock
  },
  mixins: [],
  props: [],
  data() {
    return {
      question_detail: {},
      question_tags: [],
      title: "",
      breadcrumbTitle: "",
      exam_info: { student: { first_name: "", last_name: "" } },
      pointPackages: [],
      skill: 0
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    user_exam_id() {
      return this.$route.query.user_exam_id;
    },
    questionIndex() {
      let questionIndex = 0;
      if (this.$route.query.question_index) {
        questionIndex = this.$route.query.question_index;
      }
      return questionIndex;
    },
    question_id() {
      return this.$route.query.question_id;
    },
    id() {
      return this.$route.query.id;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    }
  },
  watch: {},

  mounted() {
    this.getBrowse();
  },

  methods: {
    getAlert() {
      this.$refs.lock.getAlert();
    },
    async getBrowse() {
      const res = await TOEFL.getBrowse(this.id, {
        toefl_chapter_id: this.id
      });
      this.question_tags = res.toefl_chapter.questions;
      this.question_detail = res.toefl_chapter.questions[this.questionIndex];
      this.title =
        res.toefl_chapter.toefl_title +
        " #" +
        res.toefl_chapter.questions[this.questionIndex].order;
      this.breadcrumbTitle = res.toefl_chapter.toefl_title;
      this.skill = res.toefl_chapter.toefl_skill_id;
    },
    viewResolve(question_id) {
      this.routerPush({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id
        }
      });
    },
    viewBrowse(question_index) {
      this.routerPush({
        name: "ToeflResolveBrowse",
        query: {
          id: this.id,
          question_index
        }
      });
    },
    titleCase(str) {
      let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    }
  }
};
</script>

<style scoped>
.resolve {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 100px;
  width: 100%;
}
.cover {
  padding: 0 15px 15px;
}

.breadcrumb {
  margin: 20px 15px 20px 15px;
}
@media screen and (max-width: 768px) {
  .resolve {
    padding-top: 60px;
  }
  .breadcrumb {
    margin: 15px;
  }
}
</style>
